.AppBody {
    height: 100%;
    width: 100%;
    background-color: #afdaaf;
    overflow: hidden;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.HeadContainer {
    background-color: greenyellow;
    margin: 3%;
    border-style: solid;
    border-color: darkgreen;
    border-radius: 2.5vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
}

.ImageContainer {
    grid-row: 1/ span 3;
}

.Image {
    height: 100%;
}

.DetailContainer {
    background-color: #c6ffc6;
    margin: 3%;
    border-style: solid;
    border-color: darkgreen;
    border-radius: 2.5vh;
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 70% 30%;
    overflow: hidden;
}

.DetailContainerTitle {
    padding: 2vh;
    display: inline;
}

.DetailContainerValue {
    padding: 2vh;
    grid-area: 2 / 1;
    font-size: large;
}

.DetailContainerButton {
    grid-row: 1 / span 2;
    grid-column: 2;
    border: none;
    background-color: rgb(120, 179, 32);
    cursor: pointer;
}

.DetailContainerButton:hover {
    background-color: rgb(80, 117, 26);
    cursor: pointer;
}

.AppHeader {
    background-color: darkslategray;
    width: 100%;
    height:auto;
    margin-bottom: 2vh;
    padding: 2vh;
    display: flex;

}

.LOGO_LEW {
    width: 20vh;
    padding: 2vh;
}

.LOGO_LM{
    padding: 2vh;
    width: 30vh;
    height: 50%;
}

.VCard {
    background-color: darkgreen;
    margin: 3%;
    padding: 3vh;
    border-style: solid;
    border-color: darkgreen;
    border-radius: 2.5vh;
    font-weight: bolder;
    color: gray;
    display: flex;
    justify-content: center;
    align-content: center;
}

.VCard:hover {
    background-color: rgb(1, 68, 1);
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .AppHeader {
        background-color: darkslategray;
        width: 100%;
        height:auto;
        margin-bottom: 0.5vh;
        padding: 0.5vh;
        display: inline-flex;
    
    }
    
    .LOGO_LEW {
        width: 15vh;
        padding: 0.5vh;
    }
    
    .LOGO_LM{
        padding: 0.5vh;
        width: 23vh;
        height: 50%;
    }

    .HeadContainer {
        background-color: greenyellow;
        margin: 3%;
        border-style: solid;
        border-color: darkgreen;
        border-radius: 2.5vh;
        overflow: hidden;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 50% 50%;
    }

    .ImageContainer {
        grid-row: 1/ span 3;
        height: fit-content;
    }
    .Image {
        height: auto;
    }

    .DetailContainer {
        background-color: #c6ffc6;
        margin: 3%;
        border-style: solid;
        border-color: darkgreen;
        border-radius: 2.5vh;
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 55% 45%;
        overflow: hidden;
    }

    .DetailContainerTitle {
        padding: 2vh;
        display: inline;
    }

    .DetailContainerValue {
        grid-area: 1 / 2;
        font-size: large;
        padding: 2vh;
    }

    .DetailContainerButton {
        grid-row: 2;
        grid-column: 1 / span 2;
        border: none;
        background-color: rgb(120, 179, 32);
        cursor: pointer;
        padding: 2vh;
    }

    .VCard {
        background-color: darkgreen;
        margin: 3%;
        padding: 3vh;
        border-style: solid;
        border-color: darkgreen;
        border-radius: 2.5vh;
        font-weight: bolder;
        color: gray;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .VCard:hover {
        background-color: rgb(1, 68, 1);
        cursor: pointer;
    }

    .textsmall{
        font-size: small;
    }
    .textsmaller{
        font-size: smaller;
    }
    
    
    
  }